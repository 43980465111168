import { Button, Flex, List, Checkbox } from 'antd'
import React, { Component } from 'react'
import { connect } from 'react-redux'

export class ServicesAddOns extends Component {
    state = {
        addOns: []
    }

    handleChooseAddOns = (service) => {
        const { addOns } = this.state
        if (addOns.includes(service)) {
            this.setState({ addOns: addOns.filter(serv => serv._id !== service._id) })
        } else {
            this.setState({ addOns: [...addOns, service] })
        }
    }

    getAddOnsObj = () => {
        const { currentService, services, menu } = this.props
        const addOnsIds = currentService?.addOns || [];
        const filteredAddOnsIds = addOnsIds.filter((addOnId) => !services.some((service) => service._id === addOnId));
        const addOnsObj = filteredAddOnsIds
            .map((addOnId) => {
                for (const category of menu) {
                    const foundService = category.services.find((service) => service._id === addOnId);
                    if (foundService) {
                        return foundService;
                    }
                }
                return null;
            })
            .filter((addOn) => addOn !== null);
        return addOnsObj;
    };

    handleAddAddOnsToServices = () => {
        this.props.addAddOnsToServices(this.state.addOns)
        this.setState({ addOns: [] })
        this.props.handleOpenAddOnsModal(false)
    }

    render() {
        const { currency } = this.props
        return (
            <div style={{
                // maxHeight: "500px",
                // overflow: "hidden",
                scrollbarWidth: "none", // For Firefox
                msOverflowStyle: "none",
            }}>

                <List
                    itemLayout="horizontal"
                    dataSource={this.getAddOnsObj()}
                    renderItem={(service, index) => (
                        <List.Item className={
                            "add-on-service " +
                            (this.state.addOns.filter(
                                (
                                    item
                                ) =>
                                    item._id ===
                                    service._id
                            ).length >
                                0 ?
                                "add-on-service-selected" : "")} onClick={() => this.handleChooseAddOns(service)}>
                            <List.Item.Meta
                                avatar={<Checkbox type="checkbox" checked={this.state.addOns.some(addOn => addOn._id === service._id)} readOnly />}
                                title={<span style={{ paddingLeft: "5px" }}>{service.name}</span>}
                                description={service.description}
                            />
                            <span
                                style={{
                                    color: "#8c8c8c",
                                    paddingRight: "10px",
                                }}
                            >
                                {
                                    service.priceType === "From" ? "From" : ""
                                }
                                {" "}
                                {currency}

                                {
                                    service.price
                                }

                            </span>
                        </List.Item>
                    )}
                />
                <div style={{ position: "sticky", bottom: "0", backgroundColor: "#ffffff" }}>
                    <Flex>
                        <Button

                            size="large"

                            style={{ height: "50px", margin: "5px" }}
                            onClick={() => this.props.handleOpenAddOnsModal(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            block size="large"
                            type="primary"
                            style={{ height: "50px", margin: "5px" }}
                            onClick={this.handleAddAddOnsToServices}
                            disabled={this.state.addOns.length === 0}

                        >
                            Add
                        </Button>
                    </Flex>

                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    menu: state.checkingIn.menu,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ServicesAddOns)